import axios from "axios";
import ToastComp from "components/toast/ToastComp";

const client = axios.create({
  baseURL: process.env.REACT_APP_APIURL,
  headers: {
    authorization: "9miiF4rITSwHU2BPBwwE9U8DUBsDsL",
  },
});

const scrapperClient = axios.create({
  baseURL: process.env.REACT_APP_Scraper_APIURL,
  // headers: {
  //   authorization: "Basic dXNlcjpwYXNzd29yZA==",
  // },
});

export const request = async ({ ...options }, notify = true) => {

  const onSuccess = (response) => {
    if (notify) { 
      if (response.status === 200) {
        if (options.method === "delete") {
          ToastComp({
            varient: "success",
            message: response.message
              ? response.message
              : "Removed Successfully",
          });
        } else if (options.method === "put") {
          if (notify) {
            ToastComp({
              varient: "success",
              message: response.message
                ? response.message
                : "Updated Successfully",
            });
          }
        } else if (options.method === "post") {
          if (notify) {
            ToastComp({
              varient: "success",
              message: response.message ? response.message : "Added Successfully",
            });
            return response;
          } else {
            ToastComp({
              varient: "info",
              message: response.message ? response.message : "Already Added",
            });
          }
          return response;
        } else if (options.method === "patch") {
          if (notify) {
            ToastComp({
              varient: "success",
              message: response.message
                ? response.message
                : "Updated Successfully",
            });
          }
        }
        return response;
      } else if (response.status === 204) {
        if (notify) {
          if(options.method === "delete"){
            ToastComp({
              varient: "success",
              message: response.message ? response.message : "Deleted Successfully",
            });
          }
          
          return response;
        } else {
          return response;
        }
      } else {
        ToastComp({
          varient: "error",
          message: response.message ? response.message : "Error",
        });
        return response;
      }
    } else {
      return response;
    }
  };

  const onError = (error) => {
    console.log(
      "Error In Axios interceptor : ",
      error,
      error?.response?.data?.msg
    );
    if (notify) {
      ToastComp({
        varient: "error",
        message:
          error?.response?.data?.msg ||
          error?.message ||
          "Error ! Try Again Later",
      });
      return error;
    }
    return error;
  };

  return client(options).then(onSuccess).catch(onError);
};

export const scrapperRequest = async ({ ...options }, notify = true) => {

  const onSuccess = (response) => {
    return response;
  };

  const onError = (error) => {
    console.log(
      "Error In Axios interceptor : ",
      error,
      error?.response?.data?.msg
    );
    if (notify) {
      ToastComp({
        varient: "error",
        message:
          error?.response?.data?.msg ||
          error?.message ||
          "Error ! Try Again Later",
      });
      return error;
    }
    return error;
  };

  return scrapperClient(options).then(onSuccess).catch(onError);
};
