// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `nav.sb-topnav {
    background: linear-gradient(to right, #4099ff, #73b4ff);
    color: rgba(255, 255, 255, 0.8);
}
img.admin-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
button#dropdown-item-button {
    background: transparent;
    border: none;
}
button#dropdown-item-button:focus{
    outline: none;
    box-shadow: none;
}`, "",{"version":3,"sources":["webpack://./src/components/navBar/navbar.css"],"names":[],"mappings":"AAAA;IACI,uDAAuD;IACvD,+BAA+B;AACnC;AACA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;AACA;IACI,uBAAuB;IACvB,YAAY;AAChB;AACA;IACI,aAAa;IACb,gBAAgB;AACpB","sourcesContent":["nav.sb-topnav {\n    background: linear-gradient(to right, #4099ff, #73b4ff);\n    color: rgba(255, 255, 255, 0.8);\n}\nimg.admin-avatar {\n    width: 40px;\n    height: 40px;\n    border-radius: 50%;\n}\nbutton#dropdown-item-button {\n    background: transparent;\n    border: none;\n}\nbutton#dropdown-item-button:focus{\n    outline: none;\n    box-shadow: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
