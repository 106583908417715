import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// import { history } from '_helpers';
import { alertActions } from './alert.slice';
import { history } from '../../../utils/basicHelpers';
import axios from 'axios';
import { isEmpty } from 'lodash';
import ToastComp from 'components/toast/ToastComp';

// create slice

const name = 'auth';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const slice = createSlice({ name, initialState, reducers });

// exports

export const authActions = { ...slice.actions, ...extraActions };
export const authReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        value: JSON.parse(localStorage.getItem('auth') || '{}')
    }
}

function createReducers() {
    return {
        setAuth
    };

    function setAuth(state, action) {
        state.value = action.payload;
    }
}

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_APIURL}`;

    return {
        login: login(),
        logout: logout()
    };

    function login() {
        return createAsyncThunk(
            `${name}/login`,
            async function ({ email, password }, { dispatch }) {
                dispatch(alertActions.clear());
                try {
                    let user = await axios.post(`${baseUrl}/auth/login`,{ email, password });

                    // debugger;
                    user = user?.data?.data;

                    if(!isEmpty(user)){
                        dispatch(authActions.setAuth(user));

                        localStorage.setItem('auth', JSON.stringify(user));
    
                        const { from } = history.location.state || { from: { pathname: '/football/match' } };
                        history.navigate(from);
                    }
                    else{
                        dispatch(alertActions.error('Invalid Email Or Password, Try Again!'));

                        ToastComp({
                            varient : "error",
                            message : 'Invalid Email Or Password, Try Again!'
                        });
                    }
                    

                } catch (error) {
                    dispatch(alertActions.error(error));
                }
            }
        );
    }

    function logout() {
        return createAsyncThunk(
            `${name}/logout`,
            function (arg, { dispatch }) {
                dispatch(authActions.setAuth(null));
                localStorage.removeItem('auth');
                history.navigate('/');
            }
        );
    }
}