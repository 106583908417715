import { lazy } from "react";

//test push
const dashboardRoutes = [
  // <Route path='*' exact={true} component={My404Component} />
  {
    path: "*",
    component: lazy(() => import("./views/NotFound")),
    layout: 1,
  },
  {
    path: "/",
    component: lazy(() => import("./views/Home")),
    layout: 2,
  },
  //#region System Start

  //#region Country Start
  {
    path: "/country",
    component: lazy(() => import("./views/panels/others/country/Country")),
    layout: 1,
  },
  {
    path: "/country/add",
    component: lazy(() => import("./views/panels/others/country/CountryForm")),
    layout: 1,
  },
  {
    path: "/country/update",
    component: lazy(() => import("./views/panels/others/country/CountryForm")),
    layout: 1,
  },

  //#endregion Country end

  //#region Seasons Start
  {
    path: "/seasons",
    component: lazy(() => import("./views/panels/others/season/Season")),
    layout: 1,
  },
  {
    path: "/seasons/add",
    component: lazy(() => import("./views/panels/others/season/SeasonForm")),
    layout: 1,
  },
  {
    path: "/seasons/update",
    component: lazy(() => import("./views/panels/others/season/SeasonForm")),
    layout: 1,
  },

  //#endregion Seasons end

  //#endregion System end

  //#region Section Football

  //#region Tournament Start
  {
    path: "/football/tournament",
    component: lazy(() =>
      import("./views/panels/football/tournament/Tournament")
    ),
    layout: 1,
  },
  {
    path: "/football/tournament/detail",
    component: lazy(() =>
      import("./views/panels/football/tournament/TournamentDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/football/tournament/add",
    component: lazy(() =>
      import("./views/panels/football/tournament/TournamentForm")
    ),
    layout: 1,
  },
  {
    path: "/football/tournament/update",
    component: lazy(() =>
      import("./views/panels/football/tournament/TournamentForm")
    ),
    layout: 1,
  },
  {
    path: "/football/tournament/league/fetch-contest-details",
    component: lazy(() =>
      import("./views/panels/football/tournament/LeagueContestDetails")
    ),
    layout: 1,
  },
  //#endregion Tournament end

  //#region contest
  {
    path: "/football/contest",
    component: lazy(() => import("./views/panels/football/contest/Contest")),
    layout: 1,
  },
  {
    path: "/football/contest/detail",
    component: lazy(() =>
      import("./views/panels/football/contest/ContestDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/football/contest/add",
    component: lazy(() =>
      import("./views/panels/football/contest/ContestForm")
    ),
    layout: 1,
  },
  {
    path: "/football/contest/update",
    component: lazy(() =>
      import("./views/panels/football/contest/ContestForm")
    ),
    layout: 1,
  },
  {
    path: "/football/contest/add-competitor",
    component: lazy(() =>
      import("./views/panels/football/contest/AddParticipant")
    ),
    layout: 1,
  },
  {
    path: "/football/contest/league-table",
    component: lazy(() =>
      import("./views/panels/football/contest/leagueTable/LeagueTable")
    ),
    layout: 1,
  },
  // {
  //   path: "/football/contest/add-rounds",
  //   component: lazy(() => import("./views/panels/football/contest/AddRounds")),
  //   layout: 1,
  // },
  {
    path: "/football/contest/matches",
    component: lazy(() =>
      import("./views/panels/football/contest/ContestMatches")
    ),
    layout: 1,
  },
  {
    path: "/football/contest/rounds",
    component: lazy(() =>
      import("./views/panels/football/contest/ContestRoundsBulk")
    ),
    layout: 1,
  },
  // {
  //   path: "/football/contest/competitors/scrapper",
  //   component: lazy(() =>
  //     import("./features/competitorsscrapper/CompetitorScrapper")
  //   ),
  //   layout: 1,
  // },

  //#endregion contest end

  //#region competitor
  {
    path: "/football/competitor",
    component: lazy(() =>
      import("./views/panels/football/competitor/Competitor")
    ),
    layout: 1,
  },
  {
    path: "/football/competitor/detail",
    component: lazy(() =>
      import("./views/panels/football/competitor/CompetitorDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/football/competitor/update",
    component: lazy(() =>
      import("./views/panels/football/competitor/CompetitorForm")
    ),
    layout: 1,
  },
  {
    path: "/football/competitor/add",
    component: lazy(() =>
      import("./views/panels/football/competitor/CompetitorForm")
    ),
    layout: 1,
  },
  // {
  //   path: "/football/competitor/add-coach",
  //   component: lazy(() =>
  //     import("./views/panels/football/competitor/AssignCoachToCompForm")
  //   ),
  //   layout: 1,
  // },
  // {
  //   path: "/football/competitor/add-competitor-to-contest",
  //   component: lazy(() =>
  //     import("./views/panels/football/competitor/AddCompToContestForm")
  //   ),
  //   layout: 1,
  // },
  {
    path: "/football/competitor/add-squad",
    component: lazy(() =>
      import("./views/panels/football/competitor/AddCompetitorSquad")
    ),
    layout: 1,
  },
  {
    path: "/football/competitor/flashscore-squad",
    component: lazy(() =>
      import("./views/panels/football/competitor/FlashScoreSquad")
    ),
    layout: 1,
  },
  // {
  //   path: "/football/competitor-matches",
  //   component: lazy(() =>
  //     import("./views/panels/football/competitor/CompetitorMatches")
  //   ),
  //   layout: 1,
  // },

  //#endregion competitor end

  //#region player
  {
    path: "/football/player",
    component: lazy(() => import("./views/panels/football/player/Player")),
    layout: 1,
  },
  {
    path: "/football/player/detail",
    component: lazy(() =>
      import("./views/panels/football/player/PlayerDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/football/player/update",
    component: lazy(() => import("./views/panels/football/player/PlayerForm")),
    layout: 1,
  },
  {
    path: "/football/player/add",
    component: lazy(() => import("./views/panels/football/player/PlayerForm")),
    layout: 1,
  },
  // {
  //   path: "/football/player/transfer",
  //   component: lazy(() =>
  //     import("./views/panels/football/player/PlayerTransferForm")
  //   ),
  //   layout: 1,
  // },
  // {
  //   path: "/football/player/squad",
  //   component: lazy(() =>
  //     import("./views/panels/football/player/AddPlayerToComp")
  //   ),
  //   layout: 1,
  // },
  //#endregion player end

  //#region official
  {
    path: "/football/official",
    component: lazy(() => import("./views/panels/football/official/Official")),
    layout: 1,
  },
  {
    path: "/football/official/detail",
    component: lazy(() =>
      import("./views/panels/football/official/OfficialDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/football/official/add",
    component: lazy(() =>
      import("./views/panels/football/official/OfficialForm")
    ),
    layout: 1,
  },
  {
    path: "/football/official/update",
    component: lazy(() =>
      import("./views/panels/football/official/OfficialForm")
    ),
    layout: 1,
  },
  //#endregion official

  //#region venue
  {
    path: "/football/venue",
    component: lazy(() => import("./views/panels/football/venue/Venue")),
    layout: 1,
  },
  {
    path: "/football/venue/detail",
    component: lazy(() =>
      import("./views/panels/football/venue/VenueDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/football/venue/add",
    component: lazy(() => import("./views/panels/football/venue/VenueForm")),
    layout: 1,
  },
  {
    path: "/football/venue/update",
    component: lazy(() => import("./views/panels/football/venue/VenueForm")),
    layout: 1,
  },
  //#endregion venue

  //#region coach
  {
    path: "/football/coach",
    component: lazy(() =>
      import("./views/panels/football/coach/FootballCoach")
    ),
    layout: 1,
  },
  {
    path: "/football/coach/detail",
    component: lazy(() =>
      import("./views/panels/football/coach/CoachDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/football/coach/add",
    component: lazy(() => import("./views/panels/football/coach/CoachForm")),
    layout: 1,
  },
  {
    path: "/football/coach/update",
    component: lazy(() => import("./views/panels/football/coach/CoachForm")),
    layout: 1,
  },
  {
    path: "/football/coach/add-role",
    component: lazy(() =>
      import("./views/panels/football/coach/CoachRoleForm")
    ),
    layout: 1,
  },
  //#endregion coach

  //#region match
  {
    path: "/football/match",
    component: lazy(() =>
      import("./views/panels/football/match/FootballMatchList")
    ),
    layout: 1,
  },
  // {
  //   path: "/football/match",
  //   component: lazy(() =>
  //     import("./views/panels/football/match/FootballMatches")
  //   ),
  //   layout: 1,
  // },
  {
    path: "/football/match/add",
    component: lazy(() => import("./views/panels/football/match/MatchForm")),
    layout: 1,
  },
  {
    path: "/football/match/update",
    component: lazy(() => import("./views/panels/football/match/MatchForm")),
    layout: 1,
  },
  // {
  //   path: "/football/match/player-of-match",
  //   component: lazy(() =>
  //     import("./views/panels/football/match/PlayerOfMatchForm")
  //   ),
  //   layout: 1,
  // },
  // {
  //   path: "/football/match/official",
  //   component: lazy(() =>
  //     import("./views/panels/football/match/MatchOfficialForm")
  //   ),
  //   layout: 1,
  // },
  {
    path: "/football/match/lineup",
    component: lazy(() =>
      import("./views/panels/football/match/lineup/MatchLineUps")
    ),
    layout: 1,
  },
  // {
  //   path: "/football/match/sofa-lineups",
  //   component: lazy(() =>
  //     import("./views/panels/football/match/lineup/SofaLineupsPage")
  //   ),
  //   layout: 1,
  // },
  {
    path: "/football/match/goalcardsub/",
    component: lazy(() =>
      import("./views/panels/football/match/goalscardssubs/GoalsCardsSubs")
    ),
    layout: 1,
  },
  // {
  //   path: "/football/myMatches",
  //   component: lazy(() => import("./views/panels/football/match/FavMatches")),
  //   layout: 1,
  // },
  {
    path: "/football/match/stats",
    component: lazy(() => import("./views/panels/football/match/MatchStats")),
    layout: 1,
  },
  {
    path: "/football/match/scores",
    component: lazy(() =>
      import("./views/panels/football/match/scores/MatchScores")
    ),
    layout: 1,
  },
  // {
  //   path: "/football/player/statistics",
  //   component: lazy(() =>
  //     import("./views/panels/football/player/playerStatisticsForm")
  //   ),
  //   layout: 1,
  // },
  // {
  //   path: "/football/match/channel/add",
  //   component: lazy(() =>
  //     import("./views/panels/football/match/channel/MatchChannelForm")
  //   ),
  //   layout: 1,
  // },
  // {
  //   path: "/football/match/channel/update",
  //   component: lazy(() =>
  //     import("./views/panels/football/match/channel/MatchChannelForm")
  //   ),
  //   layout: 1,
  // },
  // {
  //   path: "/football/match-mapping",
  //   component: lazy(() =>
  //     import("./views/panels/football/match/matchmapping/MatchMapping")
  //   ),
  //   layout: 1,
  // },
  {
    path: "/football/provider-search",
    component: lazy(() =>
      import("./views/panels/football/providersearch/ProviderSearch.jsx")
    ),
    layout: 1,
  },
  // // {
  // //   path: "/football/match/channel",
  // //   component: lazy(() =>
  // //     import("./views/panels/football/match/channel/MatchChannel")
  // //   ),
  // //   layout: 1,
  // // },
  // {
  //   path: "/football/match/textComment",
  //   component: lazy(() =>
  //     import("./views/panels/football/match/comment/TextCommentList")
  //   ),
  //   layout: 1,
  // },
  // {
  //   path: "/football/match/textComment/add",
  //   component: lazy(() =>
  //     import("./views/panels/football/match/comment/TextCommentForm")
  //   ),
  //   layout: 1,
  // },
  // {
  //   path: "/football/match/minutes",
  //   component: lazy(() =>
  //     import("./views/panels/football/match/matchMinutes/MatchMinutesList")
  //   ),
  //   layout: 1,
  // },
  // {
  //   path: "/football/match/minute/add",
  //   component: lazy(() =>
  //     import("./views/panels/football/match/matchMinutes/MatchMinutesForm")
  //   ),
  //   layout: 1,
  // },
  //#endregion match

  //#endregion Section Football Ends
];

export default dashboardRoutes;