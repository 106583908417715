import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { history } from '../utils/basicHelpers';
import { isEmpty } from 'lodash';

const PrivateRoute = () => {

    const auth = useSelector(x => x.auth.value);

    if (isEmpty(auth)) {
        return <Navigate to="/" state={{ from: history.location }} />
    }

    return <Outlet />;
}

export default PrivateRoute