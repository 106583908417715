// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button123 {
    width: 28px;
    height: 22px;
    padding: 0px !important;
}
.img123 {
   width: 18px;
   height: 18px;
   margin-bottom: 5px;
}

.btnIconEye{
    margin-bottom: 9px !important;
}

.customTooltip {
    padding: 4px 4px !important;
    background: black !important;
  }`, "",{"version":3,"sources":["webpack://./src/components/buttons/Buttons.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,uBAAuB;AAC3B;AACA;GACG,WAAW;GACX,YAAY;GACZ,kBAAkB;AACrB;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,2BAA2B;IAC3B,4BAA4B;EAC9B","sourcesContent":[".button123 {\n    width: 28px;\n    height: 22px;\n    padding: 0px !important;\n}\n.img123 {\n   width: 18px;\n   height: 18px;\n   margin-bottom: 5px;\n}\n\n.btnIconEye{\n    margin-bottom: 9px !important;\n}\n\n.customTooltip {\n    padding: 4px 4px !important;\n    background: black !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
