import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./assets/styles/match1.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import "./assets/sass/common.scss";
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './store'
import { Provider } from 'react-redux'

const root = ReactDOM.createRoot(document.getElementById("root"));
const helmetContext = {title:'Entry Panel'};
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <HelmetProvider context={helmetContext}>
          <App />
        </HelmetProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>
  // </React.StrictMode>
);
reportWebVitals();
