// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainHeading{
    text-transform: uppercase;
    font-weight: 550
}
.mainHeading1{
    text-transform: uppercase;
    font-weight: 550
}
.childLabel{
    text-transform: uppercase;
    font-size: 14px;
}

.detailTableHeading{
    /* margin-bottom: 0; */
    color: #222;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
    display: inline-block;
    margin-right: 10px;
    line-height: 1.1;
    position: relative;
    padding-top: 10px;
}
.playerStatsics th, .playerStatsics td{
    padding: 10px;
}`, "",{"version":3,"sources":["webpack://./src/views/panels/DetailTable.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB;AACJ;AACA;IACI,yBAAyB;IACzB;AACJ;AACA;IACI,yBAAyB;IACzB,eAAe;AACnB;;AAEA;IACI,sBAAsB;IACtB,WAAW;IACX,eAAe;IACf,yBAAyB;IACzB,gBAAgB;IAChB,qBAAqB;IACrB,kBAAkB;IAClB,gBAAgB;IAChB,kBAAkB;IAClB,iBAAiB;AACrB;AACA;IACI,aAAa;AACjB","sourcesContent":[".mainHeading{\n    text-transform: uppercase;\n    font-weight: 550\n}\n.mainHeading1{\n    text-transform: uppercase;\n    font-weight: 550\n}\n.childLabel{\n    text-transform: uppercase;\n    font-size: 14px;\n}\n\n.detailTableHeading{\n    /* margin-bottom: 0; */\n    color: #222;\n    font-size: 16px;\n    text-transform: uppercase;\n    font-weight: 700;\n    display: inline-block;\n    margin-right: 10px;\n    line-height: 1.1;\n    position: relative;\n    padding-top: 10px;\n}\n.playerStatsics th, .playerStatsics td{\n    padding: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
