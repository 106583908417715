// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lineupHeading {
    /* background: rgb(64, 153, 255); */
    height: 40px;
    text-align: start;
    line-height: 40px;
    font-size: 1.2rem;
    padding-left: 5px;
    font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/views/panels/football/match/lineup/lineUp.css"],"names":[],"mappings":"AAAA;IACI,mCAAmC;IACnC,YAAY;IACZ,iBAAiB;IACjB,iBAAiB;IACjB,iBAAiB;IACjB,iBAAiB;IACjB,gBAAgB;AACpB","sourcesContent":[".lineupHeading {\n    /* background: rgb(64, 153, 255); */\n    height: 40px;\n    text-align: start;\n    line-height: 40px;\n    font-size: 1.2rem;\n    padding-left: 5px;\n    font-weight: 600;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
