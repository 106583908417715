// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#team-scrapper-section .team-row.mapped td {
  background-color: #83f783;
}

#matches-scrapper-section .contest-header-name td {
  background-color: #BFC9CA;
}

.contest_header.male td {
  background-color: rgb(0, 188, 212);
}

.contest_header.female td {
  background-color: rgb(219, 104, 229);
}

.loader-span-none span {
  display: none;
}
.loader-span-none.span-d-block span {
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/assets/sass/common.scss"],"names":[],"mappings":"AAGY;EACI,yBAAA;AAFhB;;AAUQ;EACI,yBAAA;AAPZ;;AAaI;EACI,kCAAA;AAVR;;AAeI;EACI,oCAAA;AAZR;;AAiBI;EACI,aAAA;AAdR;AAkBQ;EACI,cAAA;AAhBZ","sourcesContent":["#team-scrapper-section {\n    .team-row {\n        &.mapped {\n            td {\n                background-color: #83f783;\n            }\n        }\n    }\n}\n\n#matches-scrapper-section {\n    .contest-header-name {\n        td {\n            background-color: #BFC9CA;\n        }  \n    }\n}\n\n.contest_header {\n    &.male td{\n        background-color: rgb(0, 188, 212);;\n    }\n}\n\n.contest_header {\n    &.female td{\n        background-color: rgb(219, 104, 229);\n    }\n}\n\n.loader-span-none {\n    span {\n        display: none;\n    }\n\n    &.span-d-block {\n        span {\n            display: block;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
