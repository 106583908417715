// import React, { Suspense } from "react";
// import { Route, Routes } from "react-router-dom";
// import dashboardRoutes from "./routes";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "./index.css";

// import "./views/panels/DetailTable.css";

// import Spinner from "react-bootstrap/Spinner";
// import Layout from "layouts/Layouts";

// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import "components/buttons/Buttons.css";
// import "./views/panels/football/match/lineup/lineUp.css"
// import "react-datepicker/dist/react-datepicker.css";


// const App = () => {
//   const getRoutes = (routes) => {
//     return routes.map((item, key) => {
//       if (item?.layout === 1) {
//         return (
//           <Route element={<Layout />} key={key}>
//             <Route path={item.path} element={<item.component />} />
//           </Route>
//         );
//       } else if (item?.layout === 2) {
//         return (
//           <Route
//             path={item.path}
//             element={<item.component />}
//             key={key}
//             target={item.target ? item.target : null}
//           />
//         );
//       } else {
//         <Route
//           path={item.path}
//           element={<item.component />}
//           key={key}
//           target={item.target ? item.target : null}
//         />;
//       }
//     });
//   };
//   return (
//     <Suspense
//       fallback={
//         <div className="loader-style">
//           <Spinner animation="grow" />
//         </div>
//       }
//     >
//       <Routes>{getRoutes(dashboardRoutes)}</Routes>
//       <ToastContainer />
//     </Suspense>
//   );
// };

// export default App;
import React, { Suspense } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import dashboardRoutes from "./routes";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";

import "./views/panels/DetailTable.css";

import Spinner from "react-bootstrap/Spinner";
import Layout from "layouts/Layouts";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "components/buttons/Buttons.css";
import "./views/panels/football/match/lineup/lineUp.css"
import "react-datepicker/dist/react-datepicker.css";
import PrivateRoute from "./views/PrivateRoute";
import { history } from "./utils/basicHelpers";


const App = () => {
  
  const getRoutes = (routes) => {
    return routes.map((item, key) => {
      if (item?.path === "/") {
        return (
          <Route
            path={item.path}
            element={<item.component />}
            key={key}
            target={item.target ? item.target : null}
          />
        );
      }
      if (item?.layout === 1) {
        return (
          <Route element={<PrivateRoute />}>
          <Route element={<Layout />} key={key}>
            <Route path={item.path} element={<item.component />} />
          </Route>
          </Route>
        );
      } else if (item?.layout === 2) {
        return (
          <Route element={<PrivateRoute />}>
          <Route
            path={item.path}
            element={<item.component />}
            key={key}
            target={item.target ? item.target : null}
          />
          </Route>
        );
      } else {
        <Route element={<PrivateRoute />}>
        <Route
          path={item.path}
          element={<item.component />}
          key={key}
          target={item.target ? item.target : null}
        />;
        </Route>
      }
    });
  };

    history.navigate = useNavigate();
    history.location = useLocation();

  return (
    <Suspense
      fallback={
        <div className="loader-style">
          <Spinner animation="grow" />
        </div>
      }
    >
      <Routes>{getRoutes(dashboardRoutes)}</Routes>
      <ToastContainer />
    </Suspense>
  );
};

export default App;
